import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import GetInTouch from "./GetInTouch";

const Services = () => {
  const offers = [
    {
      imgSrc: "/img/service/Consistent Quality.svg",
      alt: "1.png",
      title: "Consistent Quality",
      description:
        "The generated Lorem Ipsum is therefore always free from repetition injected humour, or non-characteristic words etc. Susp endisse ultricies nisi vel quam suscipit",
    },
    {
      imgSrc: "/img/service/FSSAI.svg",
      alt: "2.png",
      title: "FSSAI-compliant Warehouses",
      description:
        "The generated Lorem Ipsum is therefore always free from repetition injected humour, or non-characteristic words etc. Susp endisse ultricies nisi vel quam suscipit",
    },
    {
      imgSrc: "/img/service/Basket Supply.svg",
      alt: "3.png",
      title: "Complete Basket Supply",
      description:
        "The generated Lorem Ipsum is therefore always free from repetition injected humour, or non-characteristic words etc. Susp endisse ultricies nisi vel quam suscipit",
    },
  ];

  const counterData = [
    {
      src: "/img/about/Bulk Buyers.svg",
      alt: "ico_count_1.png",
      count: 1042,
      title: "Suppliers Partners",
    },
    {
      src: "/img/about/Cities.svg",
      alt: "ico_count_2.png",
      count: 241,
      title: "Buyer Partners",
    },
    {
      src: "/img/about/National & Regional Retailers.svg",
      alt: "ico_count_3.png",
      count: 17,
      title: "Cities",
    },
    {
      src: "/img/about/HORECA.svg",
      alt: "ico_count_4.png",
      count: 100,
      title: "MT+ Produce Handled Daily",
    },
  ];

  const reviews = [
    {
      text: `The intend fulfillment has a deviation of less than 0.5%. Their capability to do multi location delivery has made them stand out from most of our supplier. We can see them as one of the PAN India supplier which will definitely help us to limit our supplier number to only a few.`,
      author: "Zepto",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `I have been with them since 1 years, my business has grown multifold initially I was dispatching 10 vehicles a day from past 6 month the count has gone up to 15 vehicles a day. The demand is constant and payment are on time. Overall I had a great experience`,
      author: "Onion FPO Parner, Maharastra",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `The quality control Is excellent along with that sourcing team is committed towards their delivery and make sure the produce is delivered well before time. The dump %age is less than 0.5% which allows us to book good margins`,
      author: "Azamgarh Onion Buyer Uttar Pradesh",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `The cater to all of our demands and supports us in Bangalore region. We are doing majorly onion, potato and leafy with Mulyam and supplies are always just in time`,
      author: "Compass",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
  ];

  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_service"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title text-change">
                      <span>Our </span> Services
                    </h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                        At Mulyam, we offer tailored services for different user
                        segments to optimize the fresh produce supply chain
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section section_6641d9dcb3219">
              <div className="container">
                <div className="section-heading section-heading--center">
                  <h2 className="__title">
                    <span>Our </span> Promises
                  </h2>
                  <p>
                    We believe in following all the ethics to get the produce
                    deliver. Our promises are not just the statements but the
                    rules which we abide.
                  </p>
                </div>
                <div className="feature feature--style-2">
                  <div className="__inner">
                    <div className="row" align="center">
                      {offers.map((offer, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-4" align="center">
                          <img
                                decoding="async"
                                src={offer.imgSrc}
                                className="img-fluid"
                                alt={offer.alt}
                                width={100}
                              />
                          <h5 className="__title">{offer.title}</h5>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card card-1">
              <h3 className="text-success">Grow With Us</h3>
              <strong>
                Mulyam offers growers reliable buyers, market reach, visibility,
                real-time updates about the mandi, and commodity training.
              </strong>
              <p>
                Join us to access a network of trusted partners and streamline
                your operations.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-2">
              <h3 className="text-success">Sell With Us</h3>
              <strong>
                Wholesalers benefit from direct source connections, market
                insights, order size flexibility, and institutional credit
                access through Mulyam.
              </strong>
              <p>
                Connect with us to enhance your market reach and business
                efficiency.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-3">
              <h3 className="text-success">Buy From Us</h3>
              <strong>
                Buyers can rely on Mulyam for Pan India supply, proficient
                handling, quality assurance, and a complete basket of
                commodities.
                <br />
                <br />
              </strong>
              <p>
                Partner with us for consistent quality and seamless operations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper pt-5 mt-5">
        <div className="nt-column col-sm-12 pt-5">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--gutter bg-blue counter_6641c1ae9d353"
              data-res-css=""
            >
              <div className="container">
                <div className="counter">
                  <div className="__inner">
                    <div className="row justify-content-sm-center">
                      {counterData.map((item, index) => (
                        <div
                          key={index}
                          className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3"
                        >
                          <div
                            className="__item"
                            data-aos=""
                            data-aos-delay=""
                            data-aos-offset=""
                          >
                            <div className="d-table">
                              <div className="d-table-cell align-middle">
                                <i className="__ico">
                                  <img
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                    className="img-fluid"
                                    alt={item.alt}
                                    data-lazy-src={item.src}
                                  />
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={item.src}
                                      className="img-fluid"
                                      alt={item.alt}
                                    />
                                  </noscript>
                                </i>
                              </div>
                              <div className="d-table-cell align-middle">
                                <p
                                  className="__count js-count text-warning"
                                  data-from="0"
                                  data-to={item.count}
                                >
                                  {item.count}+
                                </p>
                                <p className="__title text-warning">
                                  {item.title}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--review lazy review_6641c1ae9f6ee"
              data-src="https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/review_bg_1.png"
              data-res-css=""
            >
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    WHAT PEOPLE SAY <span>ABOUT Mulyam</span>
                  </h2>
                  {/* <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p> */}
                </div>
                <div className="review review--slider">
                  <Slider
                    autoplay={true}
                    autoplaySpeed={3000}
                    arrows={false}
                    dots={false}
                    pauseOnHover={false}
                    speed={1000}
                  >
                    {reviews.map((review, index) => (
                      <div className="review__item" key={index}>
                        <div className="review__item__text">
                          <p>
                            <i>{review.text}</i>
                          </p>
                        </div>
                        <div className="review__item__author d-table">
                          {/* <div className="d-table-cell align-middle">
                            <div className="review__item__author-image">
                              <img
                                decoding="async"
                                src={review.image}
                                className="circled"
                                alt="ava.png"
                              />
                            </div>
                          </div> */}
                          <div className="d-table-cell align-middle">
                            <span className="review__item__author-name">
                              <strong> -- {review.author}</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
};

export default Services;
