import React from "react";
import GetInTouch from "./GetInTouch";

function Features() {
  const offers = [
    {
      imgSrc:
        "/img/feature/Trusted Network Access.svg",
      alt: "1.png",
      title: "Extensive Network :",
      description:
        "Access a wide network of trusted partners to expand your reach and opportunities",
    },
    {
      imgSrc:
        "/img/feature/Effective Communication Tools.svg",
      alt: "2.png",
      title: "Effective Communication :",
      description:
        "Utilize advanced communication tools for seamless interactions with your partners.",
    },
    {
      imgSrc:
        "/img/feature/Real-Time Updates.svg",
      alt: "3.png",
      title: "Real-Time Updates :",
      description:
        "Receive real-time updates to stay informed and make timely decisions.",
    },
    {
      imgSrc:
        "/img/feature/Streamlined Operations.svg",
      alt: "3.png",
      title: "Streamlined Operations :",
      description:
        "Simplify and streamline your operations for increased efficiency and productivity.",
    },
  ];

  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_feature"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title text-dark">
                      <span>Our </span> Features
                    </h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                        Mulyam simplifies trading in fresh produce with a
                        comprehensive suite of features designed to optimize
                        your business operations.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section section_6641d9dcb3219">
              <div className="container">
                <div className="section-heading section-heading--center">
                  <h2 className="__title">
                    <span>Our </span> Features
                  </h2>
                  <p>
                    Mulyam simplifies trading in fresh produce with a
                    comprehensive suite of features designed to optimize your
                    business operations.
                  </p>
                </div>
                <div className="feature feature--style-2">
                  <div className="__inner">
                    <div className="row">
                      {offers.map((offer, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-6">
                          <div className="__item">
                            <i className="__ico">
                              <img
                                decoding="async"
                                src={offer.imgSrc}
                                className="img-fluid"
                                alt={offer.alt}
                              />
                            </i>
                            <h5 className="__title">{offer.title}</h5>
                            <p>{offer.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div align="center">
                      <strong>
                        Mulyam empowers you to make informed decisions and
                        optimize your supply chain, ensuring a smooth and
                        successful trading experience.
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--no-pb jarallax item_6641d9dcb5a00"
              data-speed="0.4"
              data-img-position="50% 65%"
            >
              <img
                src="/img/feature/bg_1.jpg"
                className="jarallax-img"
                alt="Background"
              />
              <div className="container">
                <div
                  className="section-heading section-heading--center section-heading--white"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    Explore <span> Our Features</span>
                  </h2>
                  <p className="text-white">
                  We are the one stop solution for all fruits and vegetable stakeholder providing every support from sourcing to delivering.
                  </p>
                </div>
                <div className="simple-text-block simple-text-block--no-pb">
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-md-11">
                      <div className="row justify-content-lg-between no-gutters">
                        <div className="col-12 col-lg-5">
                          <img
                            src="/img/feature/Mobile.webp"
                            className="img-fluid w-100"
                            alt="Service"
                          />
                        </div>
                        <div className="col-12 my-3 d-lg-none"></div>
                        <div className="col-12 col-lg-6 pb-5">
                          <div className="__item">
                            <h3>1. User Management</h3>
                            <p>
                            We onboard supplier & buyers after following mandatory guidelines . Also we scrutinize on every probability of defaults from buyer and seller on quality, delivery and payments. We have developed a system where business ledger for every user is clearly accessible and visible.
                            </p>
                          </div>
                          <div className="__item pt-5">
                            <h3>2. Trade Opportunities</h3>
                            <p>
                              Boost your trade opportunities with Mulyam’s
                              custom matchmaking, expert guidance, dedicated
                              support, and account managers. Our platform also
                              provides efficient logistics and financial
                              solutions to enhance your operations.
                            </p>
                          </div>
                          <div className="__item py-5">
                            <h3>3. Logistics and Financial Solutions</h3>
                            <p>
                              Mulyam offers reliable logistics solutions and
                              access to trade financing, ensuring smooth
                              operations from procurement to delivery.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}

export default Features;
