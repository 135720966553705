import React from "react";
import GetInTouch from "./GetInTouch";

function Insights() {

  const blogPosts = [
    {
      id: 1,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/4-6-370x258.jpg",
      title: "Tips for Ripening your Fruit",
      category: ["DIET", "TIPS & GUIDES"],
      date: "3 Dec",
      link: "",
    },
    {
      id: 2,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/10-370x258.jpg",
      title: "Health Benefits of a Raw Food",
      category: ["ORGANIC FOOD", "TIPS & GUIDES"],
      date: "3 Dec",
      link: "",
    },
    {
      id: 3,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/4-1-370x258.jpg",
      title: "Superfoods you should",
      category: ["DIET", "ORGANIC FOOD"],
      date: "2 Dec",
      link: "",
    },
  ];

  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_insight"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title text-white">
                        <span>Latest </span>Insights
                    </h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                      Stay updated with the latest news, industry insights, and articles related to fresh produce, finance solutions, and climate change impacts.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section-blog section--no-pb blog_6641c1aea05c6">
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    BLOGS
                  </h2>
                  <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p>
                </div>
                <div className="posts posts--style-1">
                  <div className="__inner">
                    <div className="row">
                      {blogPosts.map((post) => (
                        <div
                          key={post.id}
                          className="nt-post col-12 col-sm-6 col-lg-4"
                        >
                          <div
                            className="__item __item--preview"
                            data-aos="flip-up"
                            data-aos-delay="200"
                            data-aos-offset="0"
                          >
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                alt={post.title}
                                data-lazy-src={post.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={post.imgSrc}
                                  alt={post.title}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <p className="__category">
                                {post.category.map((cat, index) => (
                                  <React.Fragment key={index}>
                                    <a href="#" rel="category tag">
                                      {cat}
                                    </a>
                                    {index < post.category.length - 1 && " / "}
                                  </React.Fragment>
                                ))}
                              </p>
                              <h3 className="__title h5">
                                <a href={post.link}>{post.title}</a>
                              </h3>
                              <p>
                                The generated Lorem Ipsum is therefore always
                                free from repetition injected humour, or
                                non-characteristic words etc.
                              </p>
                              <a
                                href={post.link}
                                className="custom-btn custom-btn--medium custom-btn--style-1"
                              >
                                Read more
                              </a>
                            </div>
                            <span className="__date-post">
                              <strong>{post.date.split(" ")[0]}</strong>
                              {post.date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section-blog section--no-pb blog_6641c1aea05c6">
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                  Whitepapers
                  </h2>
                  <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p>
                </div>
                <div className="posts posts--style-1">
                  <div className="__inner">
                    <div className="row">
                      {blogPosts.map((post) => (
                        <div
                          key={post.id}
                          className="nt-post col-12 col-sm-6 col-lg-4"
                        >
                          <div
                            className="__item __item--preview"
                            data-aos="flip-up"
                            data-aos-delay="200"
                            data-aos-offset="0"
                          >
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                alt={post.title}
                                data-lazy-src={post.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={post.imgSrc}
                                  alt={post.title}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <p className="__category">
                                {post.category.map((cat, index) => (
                                  <React.Fragment key={index}>
                                    <a href="#" rel="category tag">
                                      {cat}
                                    </a>
                                    {index < post.category.length - 1 && " / "}
                                  </React.Fragment>
                                ))}
                              </p>
                              <h3 className="__title h5">
                                <a href={post.link}>{post.title}</a>
                              </h3>
                              <p>
                                The generated Lorem Ipsum is therefore always
                                free from repetition injected humour, or
                                non-characteristic words etc.
                              </p>
                              <a
                                href={post.link}
                                className="custom-btn custom-btn--medium custom-btn--style-1"
                              >
                                Read more
                              </a>
                            </div>
                            <span className="__date-post">
                              <strong>{post.date.split(" ")[0]}</strong>
                              {post.date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section-blog section--no-pb blog_6641c1aea05c6">
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                  Case Studies
                  </h2>
                  <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p>
                </div>
                <div className="posts posts--style-1">
                  <div className="__inner">
                    <div className="row">
                      {blogPosts.map((post) => (
                        <div
                          key={post.id}
                          className="nt-post col-12 col-sm-6 col-lg-4"
                        >
                          <div
                            className="__item __item--preview"
                            data-aos="flip-up"
                            data-aos-delay="200"
                            data-aos-offset="0"
                          >
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                alt={post.title}
                                data-lazy-src={post.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={post.imgSrc}
                                  alt={post.title}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <p className="__category">
                                {post.category.map((cat, index) => (
                                  <React.Fragment key={index}>
                                    <a href="#" rel="category tag">
                                      {cat}
                                    </a>
                                    {index < post.category.length - 1 && " / "}
                                  </React.Fragment>
                                ))}
                              </p>
                              <h3 className="__title h5">
                                <a href={post.link}>{post.title}</a>
                              </h3>
                              <p>
                                The generated Lorem Ipsum is therefore always
                                free from repetition injected humour, or
                                non-characteristic words etc.
                              </p>
                              <a
                                href={post.link}
                                className="custom-btn custom-btn--medium custom-btn--style-1"
                              >
                                Read more
                              </a>
                            </div>
                            <span className="__date-post">
                              <strong>{post.date.split(" ")[0]}</strong>
                              {post.date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper pb-5 mb-5">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section-blog section--no-pb blog_6641c1aea05c6">
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    Press Releases
                  </h2>
                  <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p>
                </div>
                <div className="posts posts--style-1">
                  <div className="__inner">
                    <div className="row">
                      {blogPosts.map((post) => (
                        <div
                          key={post.id}
                          className="nt-post col-12 col-sm-6 col-lg-4"
                        >
                          <div
                            className="__item __item--preview"
                            data-aos="flip-up"
                            data-aos-delay="200"
                            data-aos-offset="0"
                          >
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                alt={post.title}
                                data-lazy-src={post.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={post.imgSrc}
                                  alt={post.title}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <p className="__category">
                                {post.category.map((cat, index) => (
                                  <React.Fragment key={index}>
                                    <a href="#" rel="category tag">
                                      {cat}
                                    </a>
                                    {index < post.category.length - 1 && " / "}
                                  </React.Fragment>
                                ))}
                              </p>
                              <h3 className="__title h5">
                                <a href={post.link}>{post.title}</a>
                              </h3>
                              <p>
                                The generated Lorem Ipsum is therefore always
                                free from repetition injected humour, or
                                non-characteristic words etc.
                              </p>
                              <a
                                href={post.link}
                                className="custom-btn custom-btn--medium custom-btn--style-1"
                              >
                                Read more
                              </a>
                            </div>
                            <span className="__date-post">
                              <strong>{post.date.split(" ")[0]}</strong>
                              {post.date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <GetInTouch />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}

export default Insights;
