import React from "react";
import GetInTouch from "./GetInTouch";

function SustainableApproach() {
  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_sustainable"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title mobile-text-white">Sustainable Approach</h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                        Mulyam simplifies trading in fresh produce with a
                        comprehensive suite of features designed to optimize
                        your business operations.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section item_6641d6b68fcb4">
              <div className="container">
                <div className="row justify-content-xl-between">
                  <div className="col-12 my-3 d-lg-none"></div>
                  <div className="col-12 col-lg col-xl-7">
                    <h2 className="info-heading">
                      Commitment to Sustainability
                    </h2>
                    <p>
                      At Mulyam, our commitment to sustainability is at the
                      heart of everything we do. We strive to reduce food waste
                      and minimize environmental impact through innovative
                      solutions in the fresh produce supply chain.
                      <br />
                      Our platform is designed to tackle inefficiencies and
                      waste by providing real-time updates and optimizing
                      operations. This not only helps reduce food waste but also
                      lowers CO2 emissions, contributing to a healthier planet.
                      <br />
                      Since our inception, Mulyam has made significant strides
                      in reducing food waste and carbon footprints, making a
                      positive environmental impact.
                      <br />
                      We are dedicated to empowering women in the fresh produce
                      industry. We provide opportunities and support to help
                      women grow their businesses, fostering a more inclusive
                      and equitable marketplace.
                      <br /><br />
                      <strong>
                        Join us in building a sustainable future, one step at a
                        time!{" "}
                      </strong>
                    </p>
                  </div>
                  <div className="col-12 col-lg-auto text-center">
                    <img
                      decoding="async"
                      src="/img/sustainable/Frame 3.png"
                      className="img-fluid"
                      alt="img_5.png"
                      width={440}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}

export default SustainableApproach;
