import React from "react";

function FAQ() {
  return (
    <>
      {/* Hero Section */}
      <section className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6641c7bbdc1ad"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title">FAQ</h1>
                    {/* <p className="nt-hero-desc">
                      The point of using Lorem Ipsum is that it has a
                      more-or-less normal distribution of letters, as opposed to
                      using 'Content here, content here', making it look like
                      readable English
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section section_6641c7bbdc786">
              <div className="container">
                <div className="__inner">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="__item">
                        <h4 className="">
                          Q1: What is Mulyam's primary mission?
                        </h4>
                        <div className="font-16">
                          A: Mulyam's primary mission is to create a trusted,
                          transparent, and data-driven platform for B2B
                          transactions in the fresh produce industry. We aim to
                          empower every stakeholder in the supply chain with
                          technology and data-driven solutions.
                        </div>

                        <h4 className="pt-5">
                          Q2: How does Mulyam help reduce food waste?
                        </h4>
                        <div className="font-16">
                          A: Our platform is designed to reduce inefficiencies
                          in the fresh produce supply chain by providing
                          real-time updates and optimizing operations. This
                          helps minimize food waste and lower the environmental
                          impact.
                        </div>

                        <h4 className="pt-5">
                          Q3: Who can benefit from using Mulyam's platform?
                        </h4>
                        <div className="font-16">
                          A: Mulyam's platform is beneficial for all
                          stakeholders in the fresh produce supply chain,
                          including farmers, distributors, retailers, and
                          wholesalers. Our tools and features are designed to
                          streamline operations and enhance communication across
                          the network.
                        </div>

                        <h4 className="pt-5">
                          Q4: What features does Mulyam offer to optimize
                          business operations?
                        </h4>
                        <div className="font-16">
                          A: Mulyam offers an extensive network of trusted
                          partners, advanced communication tools, real-time
                          updates, and streamlined operational processes to help
                          businesses optimize their supply chains and make
                          informed decisions.
                        </div>

                        <h4 className="pt-5">
                          Q5: How does Mulyam support sustainability in the
                          fresh produce industry?
                        </h4>
                        <div className="font-16">
                          A: We are committed to sustainability by reducing food
                          waste and CO2 emissions through our efficient
                          platform. Our real-time updates and optimized
                          operations help minimize waste and environmental
                          impact, contributing to a greener future.
                        </div>

                        <h4 className="pt-5">
                          Q6: What initiatives does Mulyam have to empower women
                          in the fresh produce industry?
                        </h4>
                        <div className="font-16">
                          A: Mulyam is dedicated to empowering women by
                          providing them with opportunities and support to grow
                          their businesses within the fresh produce industry. We
                          believe in fostering a more inclusive and equitable
                          marketplace.
                        </div>

                        <h4 className="pt-5">
                          Q7: How can I join Mulyam's network?
                        </h4>
                        <div className="font-16">
                          A: Joining Mulyam's network is simple. Visit our
                          website and follow the registration process to become
                          a part of our trusted community of fresh produce
                          professionals.
                        </div>

                        <h4 className="pt-5">
                          Q8: Can Mulyam be used internationally?
                        </h4>
                        <div className="font-16">
                          A: Yes, Mulyam's platform is designed to support
                          international transactions and partnerships, enabling
                          you to expand your reach and optimize your supply
                          chain globally.
                        </div>

                        <h4 className="pt-5">
                          Q9: What kind of support does Mulyam provide to its
                          users?
                        </h4>
                        <div className="font-16">
                          A: Mulyam offers comprehensive support to its users,
                          including customer service, technical assistance, and
                          resources to help you maximize the benefits of our
                          platform.
                        </div>

                        <h4 className="pt-5">
                          Q10: How can I get in touch with Mulyam for more
                          information?
                        </h4>
                        <div className="font-16">
                          A: You can contact us through our website's contact
                          form, email us at [email address], or call us at
                          [phone number]. Our team is ready to assist you with
                          any questions or concerns you may have.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQ;
