import React, { useState } from "react";

function GetInTouch() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://sheetdb.io/api/v1/ob1mc5u20g55p", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: formData }),
      });

      if (response.ok) {
        setResponseMessage("Data submitted successfully!");
        setFormData({ name: "", email: "", message: "" }); // Reset form
        setTimeout(() => {
          window.location.href = "/thank-you"; // Simple redirection
        }, 100);
      } else {
        const errorData = await response.json();
        setResponseMessage(
          "Failed to submit data: " + (errorData.message || "Unknown error")
        );
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
    }
  };

  return (
    <div className="agro-section-wrapper ptb-140 section section--dark-bg nt_section_15416479 nt-has-responsive-data">
      <div className="container container-stretch">
        <div className="row">
          <div className="section-heading--white col-sm-12">
            <div className="nt-shortcode-wrapper">
              <div
                className="section-heading section-heading--center"
                data-aos="fade"
              >
                <h2 className="__title">
                  GET <span>IN TOUCH</span>
                </h2>
                <p>
                  Join Mulyam today and benefit from our seamless in-app
                  messaging system, live updates, and a network of trusted
                  partners.
                </p>
              </div>
              <div className="contact-form-wrapper">
                <form
                  onSubmit={handleSubmit}
                  className="wpcf7-form init demo"
                  aria-label="Contact form"
                >
                  <div className="contact-form js-contact-form">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="input-wrp">
                          <input
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required textfield"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-wrp">
                          <input
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required textfield"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="E-mail"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-wrp">
                      <textarea
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required textfield"
                        name="message"
                        placeholder="Comments"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        required
                      />
                    </div>
                    <button
                      className="wpcf7-form-control wpcf7-submit has-spinner custom-btn custom-btn--medium custom-btn--style-3 wide"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
                <div class="pt-4 text-white" align="center">
                {responseMessage && <p>{responseMessage}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;