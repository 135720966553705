import React from 'react';
import { Link } from 'react-router-dom';
import StartFromTop from './StartFromTop';

function Footer() {
  return (
    <footer id="footer" className="footer--style-1">
      <div className="container">
        <div className="row mb-40">
          {/* Footer Logo Section */}
          <div className="nt-footer-widget col-12 col-sm-auto custom-footer-widget-1">
            <div className="widget_text nt-sidebar-inner-widget widget_custom_html">
              <div className="textwidget custom-html-widget">
                <div className="footer__item">
                  <Link className="site-logo" to="/">
                    <img
                      className="img-fluid"
                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                      alt="site-logo"
                      data-lazy-src="/img/logo.png"
                    />
                    <noscript>
                      <img
                        className="img-fluid"
                        src="/img/logo.png"
                        alt="site-logo"
                      />
                    </noscript>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Contact Info */}
          <div className="nt-footer-widget col-12 col-sm custom-footer-widget-2">
            <div className="widget_text nt-sidebar-inner-widget widget_custom_html">
              <div className="textwidget custom-html-widget">
                <div className="row align-items-md-center no-gutters">
                  <div className="col-12 col-md">
                    <div className="footer__item">
                      <address>
                        <p>70/21 Pramod Apartment,
                        Pimpri Chinchwad,
                        Pune 411033</p>
                        <p>
                          <Link to="tel:919890255532">+91 98902 55532</Link> /<br/>
                          <Link to="tel:918600996491">+91 8600 996491</Link>
                        </p>
                        <p>
                          <Link to="mailto:info@mulyam.in">info@mulyam.in</Link>
                        </p>
                      </address>
                    </div>
                  </div>

                  {/* Social Links */}
                  <div className="col-12 col-md-auto py-xs-3">
                    <div className="footer__item">
                      <div className="social-btns">
                        {/* <Link target='_blank' to="#"><i class="fa-brands fa-facebook-f"></i></Link> */}
                        {/* <Link target='_blank' to="#"><i class="fa-brands fa-instagram"></i></Link> */}
                        <Link target='_blank' to="https://www.youtube.com/@Mulyamofficial"><i className="fa-brands fa-youtube"></i></Link>
                        <Link target='_blank' to="https://www.linkedin.com/company/mulyam/"><i className="fa-brands fa-linkedin"></i></Link>
                        {/* <Link target='_blank' to="#"><i class="fa-brands fa-twitter"></i></Link> */}
                        {/* <Link to="#"><i class="fa-brands fa-quora"></i></Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Newsletter Signup */}
          <div className="nt-footer-widget col-12 col-lg-5 col-xl-4 offset-xl-1 custom-footer-widget-3">
            <div className="nt-sidebar-inner-widget widget_text">
              <div className="textwidget">
                <div className="footer__item">
                  <h5 className="h6 mt-0">Get a Newsletter</h5>
                  <form
                    action=""
                    method="get"
                    className="wpcf7-form init demo"
                    aria-label="Contact form"
                    noValidate
                  >
                    <div className="form--horizontal">
                      <div className="input-wrp">
                        <input
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required textfield"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Your E-mail"
                          type="text"
                          name="text-874"
                        />
                      </div>
                      <input
                        className="wpcf7-form-control wpcf7-submit has-spinner custom-btn custom-btn--medium custom-btn--style-1"
                        type="submit"
                        value="Subscribe"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Navigation and Copyright */}
        <div className="row flex-lg-row-reverse">
          <div className="col-12 col-lg-6">
            <div className="footer__item nt-footer-nav">
              <nav id="footer__navigation" className="navigation text-lg-right">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/services">Services</Link></li>
                  <li><Link to="/faq">FAQs</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="col-12 col-lg-6 mt-xs-3">
            <div className="footer__item nt-footer-copyright">
              <span className="__copy">
                © 2024 Mulyam. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      <div id="btn-to-top-wrap">
        <Link id="btn-to-top" className="circled" to="javascript:void(0);" data-visible-offset="800"></Link>
      </div>

      <StartFromTop />
      
    </footer>
  );
}

export default Footer;
