import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isHidden, setIsHidden] = useState(false); // Track if the header should be hidden
  const [lastScrollTop, setLastScrollTop] = useState(0); // Track the last scroll position

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop && scrollTop > 80) {
        // User is scrolling down and has scrolled more than 80px
        setIsHidden(true);
      } else if (scrollTop < lastScrollTop) {
        // User is scrolling up
        setIsHidden(false);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]); // Dependency on lastScrollTop

  return (
    <header
      id="top-bar"
      className={`top-bar top-bar--style-1 site-header ${isHidden ? "hide" : ""}`} // Toggle hide class based on isHidden state
      data-ntr-header
    >
      <div className="top-bar__bg"></div>
      <div className="container-fluid bg-white">
        <div className="row align-items-center justify-content-between no-gutters">
          <Link
            to="/"
            id="nt-logo"
            className="top-bar__logo site-logo img has-sticky-logo mob-header"
          >
            <img
              src="/img/logo (2).png"
              alt="Agro"
              className="img-fluid main-logo"
            />
            <img
              src="/img/logo.png"
              alt="Agro"
              className="img-fluid sticky-logo"
            />
          </Link>

          <button
            id="top-bar__navigation-toggler"
            className="top-bar__navigation-toggler top-bar__navigation-toggler--light"
            onClick={() => console.log("Toggled navigation")}
          >
            <span></span>
          </button>

          <div id="top-bar__inner" className="top-bar__inner">
            <nav
              id="top-bar__navigation"
              className="top-bar__navigation navigation"
              role="navigation"
            >
              <ul className="menu desktop-only">
                <li className="menu-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item">
                  <Link to="/services">Our Services</Link>
                </li>
                <li className="menu-item">
                  <Link to="/features">Our Features</Link>
                </li>
                <li className="menu-item">
                  <Link to="/sustainable-approach">Sustainable Approach</Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/insights">Latest Insights</Link>
                </li> */}
                {/* <li className="menu-item">
                  <Link to="/contact">Contact</Link>
                </li> */}
                <li className="menu-item-last-child li-btn">
                  <Link
                    to="/contact"
                    rel="noopener noreferrer"
                    className="custom-btn custom-btn--small custom-btn--style-4"
                  >
                    Get in Touch
                  </Link>
                </li>
              </ul>

              <ul className="menu mobile-only">
                <li className="menu-item">
                  <Link to="/" onClick={() => window.location.href = "/"}>Home</Link>
                </li>
                <li className="menu-item">
                  <Link to="/about" onClick={() => window.location.href = "/about"}>About Us</Link>
                </li>
                <li className="menu-item">
                  <Link to="/services" onClick={() => window.location.href = "/services"}>Our Services</Link>
                </li>
                <li className="menu-item">
                  <Link to="/features" onClick={() => window.location.href = "/features"}>Our Features</Link>
                </li>
                <li className="menu-item">
                  <Link to="/sustainable-approach" onClick={() => window.location.href = "/sustainable-approach"}>Sustainable Approach</Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/insights" onClick={() => window.location.href = "/insights"}>Latest Insights</Link>
                </li> */}
                {/* <li className="menu-item">
                  <Link to="/contact" onClick={() => window.location.href = "/contact"}>Contact</Link>
                </li> */}
                <li className="menu-item-last-child li-btn">
                  <Link
                    to="/contact"
                    rel="noopener noreferrer"
                    className="custom-btn custom-btn--small custom-btn--style-4"
                    onClick={() => window.location.href = "/contact"}
                  >
                    Get in Touch
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
