import React from "react";
import GetInTouch from "./GetInTouch";

function About() {

  const counterData = [
    {
      src: "/img/about/Bulk Buyers.svg",
      alt: "ico_count_1.png",
      count: 1042,
      title: "Suppliers Partners",
    },
    {
      src: "/img/about/Cities.svg",
      alt: "ico_count_2.png",
      count: 241,
      title: "Buyer Partners",
    },
    {
      src: "/img/about/National & Regional Retailers.svg",
      alt: "ico_count_3.png",
      count: 17,
      title: "Cities",
    },
    {
      src: "/img/about/HORECA.svg",
      alt: "ico_count_4.png",
      count: 100,
      title: "MT+ Produce Handled Daily",
    },
  ];

  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_1549350340527"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title text-white">
                      <span>About </span> MULYAM
                    </h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "26px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                        Building a Transformative Ecosystem for a Stronger
                        Future
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--no-pb about--1 md-hide-bg item_6642464b76779 vc_custom_1549761597125"
              data-res-css=""
            >
              <div className="container">
                <div className="section-heading">
                  <h2 className="__title">
                    WE ARE <span>MULYAM</span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 col-xl-8">
                    <p>
                      In India, 28% of fruits and vegetables perish every year
                      due to inefficiencies in the supply chain. Mulyam is
                      dedicated to addressing these challenges head-on by
                      providing a reliable, transparent, and data-driven
                      platform for B2B fresh produce transactions.
                    </p>

                    <p>
                      We are revolutionizing the way fresh produce is traded,
                      ensuring that every stakeholder in the supply chain
                      benefits from reduced waste, increased efficiency, and
                      enhanced market opportunities.
                    </p>

                    <p>
                      We are committed to creating a seamless and efficient
                      fresh produce market that empowers farmers and enriches
                      markets, driving positive change in the industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section item_6641d6b68fcb4">
              <div className="container">
                <div className="row justify-content-xl-between">
                  <div className="col-12 col-lg-auto text-center">
                    <img
                      decoding="async"
                      src="/img/about/Frame_02.png"
                      className="img-fluid"
                      alt="img_5.png"
                      width={380}
                    />
                  </div>
                  <div className="col-12 my-3 d-lg-none"></div>
                  <div className="col-12 col-lg col-xl-7">
                    <p>
                      <strong>
                      Founded with the vision to revolutionize the fresh produce supply chain, Mulyam has a rich 
                      history of innovation and dedication.
                      </strong>
                    </p>
                    <h2 className="info-heading">
                      Our Mission
                    </h2>
                    <p>
                    To create a trusted, transparent, and data-driven platform for B2B transactions in the fresh produce industry.
                    </p>

                    <h2 className="info-heading">
                      Our Vision
                    </h2>
                    <p>
                    To establish a seamless, efficient, and transparent market for fresh produce, empowering every stakeholder in the supply chain through technology and data-driven solutions.
                    </p>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--gutter bg-blue counter_6641c1ae9d353"
              data-res-css=""
            >
              <div className="container">
                <div className="counter">
                  <div className="__inner">
                    <div className="row justify-content-sm-center">
                      {counterData.map((item, index) => (
                        <div
                          key={index}
                          className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3"
                        >
                          <div
                            className="__item"
                            data-aos=""
                            data-aos-delay=""
                            data-aos-offset=""
                          >
                            <div className="d-table">
                              <div className="d-table-cell align-middle">
                                <i className="__ico">
                                  <img
                                    decoding="async"
                                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                    className="img-fluid"
                                    alt={item.alt}
                                    data-lazy-src={item.src}
                                  />
                                  <noscript>
                                    <img
                                      decoding="async"
                                      src={item.src}
                                      className="img-fluid"
                                      alt={item.alt}
                                    />
                                  </noscript>
                                </i>
                              </div>
                              <div className="d-table-cell align-middle">
                                <p
                                  className="__count js-count text-warning"
                                  data-from="0"
                                  data-to={item.count}
                                >
                                  {item.count}+ 
                                </p>
                                <p className="__title text-warning">{item.title}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="nt-column">
        <div className="nt-shortcode-wrapper">
          <div className="section section--no-pb section--custom-03">
            <div className="container">
              <div className="section-heading section-heading--center" data-aos="fade">
                <h2 className="__title">Our dream <span>team</span></h2>
              </div>
              <div className="team">
                <div className="__inner">
                  <div className="row">
                    {[
                      { name: 'Yogesh Kedari', title: 'Founder & CEO', img: '/img/team/Yogesh.webp' },
                      { name: 'Mahesh Kedari', title: 'Co-Founder & CTO', img: 'img/team/Mahesh.webp' },
                      { name: 'Preetesh Dutt', title: 'Co-Founder & Head of Sales', img: 'img/team/Preetesh.webp' },
                      { name: 'Santosh Shitole', title: 'Co-Founder & Head of Procurement', img: 'img/team/Santosh.webp' },
                      { name: 'Vivek Dhoble', title: 'Chief Business Officer', img: 'img/team/Vivek.webp' },
                      { name: 'Sameer Wagchoure', title: 'Head of Operations', img: 'img/team/Smaeer.webp' },
                      { name: 'Rishabh Bilala', title: 'Head of Marketing', img: 'img/team/Rishabh.webp' },
                      { name: 'Rahul', title: 'DGM Sales', img: 'img/team/Rahul.webp' }
                    ].map((teamMember, index) => (
                      <div className="col-12 col-md-6 col-lg-4" key={index}>
                        <div className="__item">
                          <figure className="__image">
                            <img
                              decoding="async"
                              src={`${teamMember.img}`}
                              className="img-fluid"
                              alt={teamMember.img}
                            />
                          </figure>
                          <div className="__content">
                            <h5 className="__title">{teamMember.name}</h5>
                            <span>{teamMember.title}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <GetInTouch />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
        width="100%"
        height="300px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}

export default About;
