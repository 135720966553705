import React, { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://sheetdb.io/api/v1/ob1mc5u20g55p", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: formData }),
      });

      if (response.ok) {
        setResponseMessage("Data submitted successfully!");
        setFormData({ name: "", email: "", message: "" }); // Reset form
        setTimeout(() => {
          window.location.href = "/thank-you"; // Simple redirection
        }, 100);
      } else {
        const errorData = await response.json();
        setResponseMessage(
          "Failed to submit data: " + (errorData.message || "Unknown error")
        );
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
    }
  };
  
  return (
    <>
      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              id="hero"
              className="nt-shortcode-hero jarallax section_6642464b7614a vc_custom_contact"
              data-speed="0.7"
              data-img-position="50% 80%"
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <h1 className="__title">Contact us</h1>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "26px",
                        color: "#ffc400",
                      }}
                    >
                      <span>
                        Get is touch with us for more information and to become
                        Mulyam mitra
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Info Section */}
      <section className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section section_6641c7bbdc786">
              <div className="container">
                <div className="company-contacts text-center">
                  <div className="__inner">
                    <div className="row justify-content-around">
                      <div className="col-12 col-md-4 col-lg-3">
                        <div className="__item">
                          <img
                            src="img/contact/Contact Us Icons-01.svg"
                            width={80}
                          />
                          <h4 className="__title">ADDRESS</h4>
                          <p>
                            70/21 Pramod Apartment,
                            <br />
                            Pimpri Chinchwad,
                            <br />
                            Pune 411033
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-3">
                        <div className="__item">
                          <img
                            src="img/contact/Contact Us Icons-02.svg"
                            width={80}
                          />
                          <h4 className="__title">PHONE</h4>
                          <p>
                            <a href="tel:919890255532">+91 98902 55532</a> /
                            <br />
                            <a href="tel:918600996491">+91 8600 996491</a>
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-3">
                        <div className="__item">
                          <img
                            src="img/contact/Contact Us Icons-03.svg"
                            width={80}
                          />
                          <h4 className="__title">E-MAIL</h4>
                          <p>
                            <a href="mailto:info@mulyam.in">info@mulyam.in</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section section--dark-bg section--contacts section_6641c7bbdcb5f">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-12 col-md-6">
                    <div className="row justify-content-end">
                      <div className="col-md-11">
                        <div className="section-heading section-heading--white">
                          <h2 className="__title">
                            GET <span>IN TOUCH</span>
                          </h2>
                          <p>
                            Join Mulyam today and benefit from our seamless
                            in-app messaging system, live updates, and a network
                            of trusted partners.
                          </p>
                        </div>
                        <form
                  onSubmit={handleSubmit}
                  className="wpcf7-form init demo"
                  aria-label="Contact form"
                >
                          <div className="contact-form js-contact-form">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="input-wrp">
                                <input
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required textfield"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                          />
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="input-wrp">
                                <input
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required textfield"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="E-mail"
                            required
                          />
                                </div>
                              </div>
                            </div>
                            <div className="input-wrp">
                            <textarea
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required textfield"
                        name="message"
                        placeholder="Comments"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        required
                      />
                            </div>
                            <button
                              className="wpcf7-form-control wpcf7-submit has-spinner custom-btn custom-btn--medium custom-btn--style-3 wide"
                              type="submit"
                            >
                              Send
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-6 map-container map-container--left">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.51971253691!2d73.7821299!3d18.6406603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9023792e783%3A0x9fa16dd248a4b7fc!2sMulyam%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1725739775446!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
