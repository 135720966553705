import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import GetInTouch from "./GetInTouch";

const AgroSection = () => {
  // Slick settings
  const settings = {
    autoplay: true,
    fade: true,
    speed: 1200,
    arrows: false,
    dots: false,
    infinite: true, // Added to ensure the carousel loops
  };

  // Data for features section
  const features = [
    {
      imgSrc: "img/home/Procurement As A Service.svg",
      title: "Procurement As A Service",
      delay: "100",
    },
    {
      imgSrc: "img/home/Streamlined Operations.svg",
      title: "Market Linkage As A Service",
      delay: "250",
    },
    {
      imgSrc: "img/home/Effective Communication Tools.svg",
      title: "Logistics Support",
      delay: "300",
    },
    {
      imgSrc: "img/home/Real-Time Updates.svg",
      title: "Trade Financing",
      delay: "200",
    },
    {
      imgSrc: "img/home/AI-Enabled Decision Support System.svg",
      title: "AI-Enabled Decision Support System",
      delay: "50",
    },
    {
      imgSrc: "img/home/Trusted Network Access.svg",
      title: "Trusted Buyer & Seller Network",
      delay: "150",
    },
  ];

  const products = [
    {
      imgSrc: "/img/products/Apples.webp",
      alt: "1.jpg",
      title: "Apples",
      link: "/img/products/Apples.webp",
      offset: "",
    },
    {
      imgSrc: "/img/products/Kinnow.webp",
      alt: "2.jpg",
      title: "Kinnow",
      link: "",
      offset: "",
    },
    {
      imgSrc: "/img/products/Orange.webp",
      alt: "3.jpg",
      title: "Orange",
      link: "",
      offset: "",
    },
    {
      imgSrc: "/img/products/Garlic.webp",
      alt: "4.jpg",
      title: "Garlic",
      link: "",
      offset: "",
    },
    {
      imgSrc: "/img/products/Ginger.webp",
      alt: "5.jpg",
      title: "Ginger",
      link: "",
      offset: "",
    },
    {
      imgSrc: "/img/products/Potato.webp",
      alt: "6.jpg",
      title: "Potato",
      link: "",
      offset: "",
    },
    {
      imgSrc: "/img/products/Onions.webp",
      alt: "7.jpg",
      title: "Onions",
      link: "",
      offset: "",
    },
  ];

  const reviews = [
    {
      text: `The intend fulfillment has a deviation of less than 0.5%. Their capability to do multi location delivery has made them stand out from most of our supplier. We can see them as one of the PAN India supplier which will definitely help us to limit our supplier number to only a few.`,
      author: "Zepto",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `I have been with them since 1 years, my business has grown multifold initially I was dispatching 10 vehicles a day from past 6 month the count has gone up to 15 vehicles a day. The demand is constant and payment are on time. Overall I had a great experience`,
      author: "Onion FPO Parner, Maharastra",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `The quality control Is excellent along with that sourcing team is committed towards their delivery and make sure the produce is delivered well before time. The dump %age is less than 0.5% which allows us to book good margins`,
      author: "Azamgarh Onion Buyer Uttar Pradesh",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
    {
      text: `The cater to all of our demands and supports us in Bangalore region. We are doing majorly onion, potato and leafy with Mulyam and supplies are always just in time`,
      author: "Compass",
      image: "/themes/agro/wp-content/uploads/2019/02/ava.png",
    },
  ];

  const blogPosts = [
    {
      id: 1,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/4-6-370x258.jpg",
      title: "Tips for Ripening your Fruit",
      category: ["DIET", "TIPS & GUIDES"],
      date: "3 Dec",
      link: "",
    },
    {
      id: 2,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/10-370x258.jpg",
      title: "Health Benefits of a Raw Food",
      category: ["ORGANIC FOOD", "TIPS & GUIDES"],
      date: "3 Dec",
      link: "",
    },
    {
      id: 3,
      imgSrc:
        "https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/4-1-370x258.jpg",
      title: "Superfoods you should",
      category: ["DIET", "ORGANIC FOOD"],
      date: "2 Dec",
      link: "",
    },
  ];

  const logoSettings = {
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    speed: 1000,
    responsive: [
      { breakpoint: 576, settings: { slidesToShow: 1 } },
      { breakpoint: 767, settings: { slidesToShow: 3 } },
      { breakpoint: 991, settings: { slidesToShow: 4 } },
      { breakpoint: 1199, settings: { slidesToShow: 5 } },
    ],
  };

  const images = [
    "/img/logo/1.png",
    "/img/logo/2.png",
    "/img/logo/3.png",
    "/img/logo/4.png",
    "/img/logo/5.png",
    "/img/logo/6.png",
    "/img/logo/7.png",
    "/img/logo/8.png",
  ];

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <>
      {/* Carousel Section */}
      <div className="agro-section-wrapper vc_custom_1576823897457 nt_section_15416246 nt-has-responsive-data">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <Slider
              {...settings}
              className="start-screen start-screen--style-4"
            >
              {/* Slide 1 */}
              <div className="start-screen__slide item_6641c7b378f50">
                <div
                  className="start-screen__bg rocket-lazyload desktop-only"
                  style={{
                    backgroundImage: "url(img/home/banner2.webp)",
                    backgroundPosition: "top left 40%",
                  }}
                />
                <div
                  className="start-screen__bg rocket-lazyload mobile-only"
                  style={{
                    backgroundImage: "url(img/mob/home.webp)",
                    backgroundPosition: "top left 50%",
                  }}
                />
                <div className="start-screen__content__item align-items-center">
                  <div className="container">
                    <div className="row justify-content-start">
                      <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7 padT-20">
                        <h2 className="__title text-white">
                          From <b>Farm </b>
                          <br />
                          To <b>Market</b>
                        </h2>
                        <p className="__desc text-white">
                          Facilitating seamless connections between farmers and
                          buyers on <br /> a national scale via technology-based
                          solutions !
                        </p>
                        <p className="mt-5 mt-md-8">
                          <span className="d-none d-sm-block">
                            <Link
                              className="custom-btn custom-btn--big custom-btn--style-5"
                              to="/about"
                            >
                              Discover
                            </Link>
                          </span>
                          <span className="d-block d-sm-none">
                            <Link
                              className="custom-btn custom-btn--small custom-btn--style-5"
                              href="/about"
                            >
                              Discover
                            </Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Slide 2 */}
            </Slider>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper section vc_custom_1549758588878">
        <div className="container container-stretch">
          <div className="row">
            <div className="nt-column">
              <div className="nt-shortcode-wrapper">
                <div
                  className="special-offer special-offer--style-1 item_6641c1ae9c344"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-offset="70"
                  data-res-css=""
                >
                  <h2
                    className="text text-center lazy bg-text"
                    data-src="/img/home/special_offer_text_bg.jpg"
                  >
                    Empowering Farmers, Enriching Markets
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div className="section item_6641c1ae9c9c8" data-res-css="">
              <div className="d-none d-lg-block bg-absolute">
                <img
                  decoding="async"
                  src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                  className="img-fluid"
                  alt="agro.png"
                  data-lazy-src="/img/agro.png"
                />
                <noscript>
                  <img
                    decoding="async"
                    src="/img/agro.png"
                    className="img-fluid"
                    alt="agro.png"
                  />
                </noscript>
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-4">
                    <div
                      data-aos="fade-left"
                      data-aos-delay="400"
                      data-aos-offset="100"
                    >
                      <div className="section-heading">
                        <h2 className="__title">
                          Who Are We ?
                        </h2>
                      </div>
                      <p className="d-none d-sm-block">
                        <Link
                          className="custom-btn custom-btn--medium custom-btn--style-1"
                          to="/about"
                        >
                          Learn More
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 my-3 d-lg-none"></div>
                  <div className="col-12 col-lg-4 text-center">
                    <div
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-offset="100"
                    >
                      <img
                        decoding="async"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                        className="img-fluid"
                        alt="img_1.png"
                        data-lazy-src="img/img_1.png"
                      />
                      <noscript>
                        <img
                          decoding="async"
                          src="img/img_1.png"
                          className="img-fluid"
                          alt="img_1.png"
                        />
                      </noscript>
                    </div>
                  </div>
                  <div className="col-12 my-3 d-lg-none"></div>
                  <div className="col-12 col-lg-4">
                    <div
                      data-aos="fade-right"
                      data-aos-delay="400"
                      data-aos-offset="100"
                    >
                      <p>
                        In India, 28% of fruits and vegetables perish every year
                        due to inefficiencies in the supply chain. Mulyam is
                        dedicated to addressing these challenges head-on by
                        providing a reliable, transparent, and data-driven
                        platform for B2B fresh produce transactions.
                      </p>
                      <p>
                        We are revolutionizing the way fresh produce is traded,
                        ensuring that every stakeholder in the supply chain
                        benefits from reduced waste, increased efficiency, and
                        enhanced market opportunities.
                      </p>
                      <p>
                        We are committed to creating a seamless and efficient
                        fresh produce market that empowers farmers and enriches
                        markets, driving positive change in the industry.
                      </p>
                      <p className="d-sm-none">
                        <a
                          className="custom-btn custom-btn--medium custom-btn--style-1"
                          href="/about"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--no-pt section--no-pb section--gutter"
              data-res-css=".simple-banner.simple-banner--style-1.banner_6641c1ae9ff68 .banner__text .__title {display: block; color: #c6c820; }"
            >
              <div
                className="simple-banner simple-banner--style-1 banner_6641c1ae9ff68 vc_custom_1549810050412"
                data-aos="fade"
                data-aos-offset="50"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="__inner">
                        <img
                          decoding="async"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          className="img-fluid w-100px"
                          alt="site_logo.png"
                          data-lazy-src="/img/logo.png"
                        />
                        <noscript>
                          <img
                            decoding="async"
                            src="/img/logo.png"
                            className="img-fluid"
                            alt="site_logo.png"
                          />
                        </noscript>
                        <div className="row">
                          <div className="col-12 col-lg-7 col-xl-7">
                            <div
                              className="banner__text"
                              data-aos="fade-left"
                              data-delay="500"
                            >
                              <h2 className="__title h1">
                                <b>Trust, Transparency & Reliability!</b>
                              </h2>
                              <p>
                                We aim to build a tech-enabled and data-driven
                                platform that serves every stakeholder in the
                                fruits and vegetables supply chain. <br />
                                <br />
                                Founded by industry experts with decades of
                                experience, Mulyam is dedicated to transforming
                                the fresh produce market.
                              </p>
                              <p>
                                <Link
                                  className="custom-btn custom-btn--medium custom-btn--style-1"
                                  to="/about#team"
                                >
                                  Meet The team
                                </Link>
                              </p>
                            </div>
                          </div>
                          <div
                            className="col-12 col-lg-5 col-xl-5 pt-5"
                            align="right"
                          >
                            <img src="/img/Our Store-01.webp" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="agro-section-wrapper py-5 my-5">
        <div className="container container-stretch py-5 my-5">
          <div className="row">
            <div className="nt-column col-sm-12">
              <div className="nt-shortcode-wrapper">
                <div
                  className="section-heading section-heading--center item_6641c1ae9ab47"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    <span>What Do </span> We Offer ?
                  </h2>
                  <p>
                    At Mulyam, we offer tailored services for different user
                    segments to optimize the fresh produce supply chain.
                  </p>
                </div>
              </div>
            </div>
            {features.map((feature, index) => (
              <div
                className="nt-column col-sm-4 col-lg-2 col-md-4 col-6"
                key={index}
              >
                <div className="nt-shortcode-wrapper">
                  <div
                    className={`feature feature--style-1 feature_${index}`}
                    data-aos="fade"
                    data-aos-delay={feature.delay}
                  >
                    <div className="__item text-center">
                      <i className="__ico">
                        <img
                          decoding="async"
                          src={`data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E`}
                          className="img-fluid"
                          alt={`${index + 1}.png`}
                          data-lazy-src={feature.imgSrc}
                        />
                        <noscript>
                          <img
                            decoding="async"
                            src={feature.imgSrc}
                            className="img-fluid"
                            alt={`${index + 1}.png`}
                          />
                        </noscript>
                      </i>
                      <h5 className="__title">{feature.title}</h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="nt-column col-sm-12" align="center">
              <Link
                className="custom-btn custom-btn--medium custom-btn--style-1"
                to="/services"
              >
                Explore Our Services
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper my-5 py-5">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--no-pt section--no-pb section--gutter item_6641c1ae9e635"
              data-res-css=""
            >
              <div className="container-fluid px-md-0">
                <div className="product-preview product-preview--style-1">
                  <div className="__inner">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="__item">
                          <div className="__intro-text">
                            <div className="row">
                              <div className="col-md-11">
                                <h2 className="text-xs-center">
                                  Our Focused <span>Commodities</span>
                                </h2>
                                <p>
                                  Sourced Directly from farms, sorted & graded
                                  with care and , Finally, Delivered across PAN
                                  India with complete control over quality
                                </p>
                                <p>
                                  <Link
                                    className="custom-btn custom-btn--medium custom-btn--style-1"
                                    to="/features"
                                  >
                                    All products
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {products.map((product, index) => (
                        <div
                          key={index}
                          className={`col-12 col-md-${
                            index === 5 || index === 6 ? "12" : "4"
                          } col-xl-${index === 2 || index === 6 ? "6" : "3"} ${
                            product.offset
                          }`}
                        >
                          <div className="__item">
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                className="img-fluid"
                                alt={product.alt}
                                data-lazy-src={product.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={product.imgSrc}
                                  className="img-fluid"
                                  alt={product.alt}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <h3 className="__title">{product.title}</h3>
                            </div>
                            <Link className="__link" href={product.link}></Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div
              className="section section--review lazy review_6641c1ae9f6ee"
              data-src="https://ninetheme.com/themes/agro/wp-content/uploads/2019/02/review_bg_1.png"
              data-res-css=""
            >
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    WHAT PEOPLE SAY <span>ABOUT MULYAM</span>
                  </h2>
                  {/* <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p> */}
                </div>
                <div className="review review--slider">
                  <Slider
                    autoplay={true}
                    autoplaySpeed={3000}
                    arrows={false}
                    dots={false}
                    pauseOnHover={false}
                    speed={1000}
                  >
                    {reviews.map((review, index) => (
                      <div className="review__item" key={index}>
                        <div className="review__item__text">
                          <p>
                            <i>{review.text}</i>
                          </p>
                        </div>
                        <div className="review__item__author d-table">
                          {/* <div className="d-table-cell align-middle">
                            <div className="review__item__author-image">
                              <img
                                decoding="async"
                                src={review.image}
                                className="circled"
                                alt="ava.png"
                              />
                            </div>
                          </div> */}
                          <div className="d-table-cell align-middle">
                            <span className="review__item__author-name">
                              <strong> -- {review.author}</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section-blog section--no-pb blog_6641c1aea05c6">
              <div className="container">
                <div
                  className="section-heading section-heading--center"
                  data-aos="fade"
                >
                  <h2 className="__title">
                    BLOG <span>POSTS</span>
                  </h2>
                  <p>
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                  </p>
                </div>
                <div className="posts posts--style-1">
                  <div className="__inner">
                    <div className="row">
                      {blogPosts.map((post) => (
                        <div
                          key={post.id}
                          className="nt-post col-12 col-sm-6 col-lg-4"
                        >
                          <div
                            className="__item __item--preview"
                            data-aos="flip-up"
                            data-aos-delay="200"
                            data-aos-offset="0"
                          >
                            <figure className="__image">
                              <img
                                decoding="async"
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                alt={post.title}
                                data-lazy-src={post.imgSrc}
                              />
                              <noscript>
                                <img
                                  decoding="async"
                                  src={post.imgSrc}
                                  alt={post.title}
                                />
                              </noscript>
                            </figure>
                            <div className="__content">
                              <p className="__category">
                                {post.category.map((cat, index) => (
                                  <React.Fragment key={index}>
                                    <Link to="#" rel="category tag">
                                      {cat}
                                    </Link>
                                    {index < post.category.length - 1 && " / "}
                                  </React.Fragment>
                                ))}
                              </p>
                              <h3 className="__title h5">
                                <Link to={post.link}>{post.title}</Link>
                              </h3>
                              <p>
                                The generated Lorem Ipsum is therefore always
                                free from repetition injected humour, or
                                non-characteristic words etc.
                              </p>
                              <a
                                href={post.link}
                                className="custom-btn custom-btn--medium custom-btn--style-1"
                              >
                                Read more
                              </a>
                            </div>
                            <span className="__date-post">
                              <strong>{post.date.split(" ")[0]}</strong>
                              {post.date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="agro-section-wrapper">
        <div className="nt-column col-sm-12">
          <div className="nt-shortcode-wrapper">
            <div className="section section_6641c1aea27a8">
              <div className="container">
                <div className="partners-list">
                  <Slider {...logoSettings}>
                    {images.map((src1, index1) => (
                      <div
                        className="__item d-flex justify-content-center align-items-center"
                        key={index1}
                      >
                        <img
                          src={src1}
                          className="img-fluid"
                          alt={`image-${index1 + 1}`}
                          width={130}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </>
  );
};

export default AgroSection;
