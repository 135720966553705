import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import "./App.css"; // Import your App-specific CSS
import Landing from "./Components/Landing";
import About from "./Components/About";
import Contact from "./Components/Contact";
import FAQ from "./Components/FAQ";
import Services from "./Components/Services";
import Features from "./Components/Features";
import SustainableApproach from "./Components/SustainableApproach";
import Insights from "./Components/Insights";

function App() {
  return (
    <Router>
      <Header />
      <div>
        <main role="main">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/features" element={<Features />} />
            <Route
              path="/sustainable-approach"
              element={<SustainableApproach />}
            />
            <Route path="/insights" element={<Insights />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            {/* Add other routes as needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
