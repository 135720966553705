import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const StartFromTop = () => {
  const { pathname } = useLocation(); // Get current route pathname

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the pathname changes
  }, [pathname]); // Trigger on every route change

  return null; // No UI is needed
};

export default StartFromTop;
